/**
 * DataZoom component entry
 */

import './dataZoom/typeDefaulter';

import './dataZoom/DataZoomModel';
import './dataZoom/DataZoomView';

import './dataZoom/SelectZoomModel';
import './dataZoom/SelectZoomView';

import './dataZoom/dataZoomProcessor';
import './dataZoom/dataZoomAction';
